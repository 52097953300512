import Vue from 'vue'
import VueRouter from 'vue-router'
import checkAuth from './checkAuth.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('../views/Auth/Container.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/TDV/Home/Container.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/TDV/404')
  },
  {
    path: '/tao-don',
    name: 'TaoDon',
    component: () => import('../views/TDV/DonGop/TaoDon.vue'),
    beforeEnter: checkAuth.auth,
  },
  {
    path: '/gopdon',
    name: 'gopdon',
    component: () => import('../views/TDV/GopDonToanBo/Container.vue'),
    beforeEnter: checkAuth.auth,
  },
  { path: "*", component: () => import('../views/TDV/404') }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
