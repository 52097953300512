<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<script>
  export default {
    name: 'home',
    data: function () {
      return {
      }
    },
  }
</script>