import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    date_pickup: '',
  	tdv: {},
  	orders: [],
    shippers: [],
    services: []
  },
  mutations: {
    VUEX_SOCKET_V2_CLIENT_TDV(state, tdv){
  		state.tdv = tdv
    },
    VUEX_SOCKET_V2_CLIENT_SERVICES(state, services) {
      state.services = services
    },
    ///////// DÀNH CHO SHIPPERS
    VUEX_SOCKET_V2_CLIENT_SHIPPERS(state, shippers){
      state.shippers = shippers
    },
    VUEX_SOCKET_V2_CLIENT_UPDATE_SHIPPER(state, shipper){ // Update lại thông tin shipper
      var shippers = state.shippers;
      var index = -1;
  		for (var i = shippers.length - 1; i >= 0; i--) {
        if (shippers[i].shipper_id == shipper.shipper_id) {
          index = i;
          Vue.set(shippers, index, shipper);
          break;
        }
      }
      if(index == -1){
        shippers.push(shipper);
      }
      state.shippers = shippers
    },
    VUEX_SOCKET_V2_CLIENT_NEW_SHIPPER(state, shipper){ // thêm shipper mới vào danh sách
      state.shippers.push(shipper)  
    },
    VUEX_SOCKET_V2_CLIENT_REMOVE_SHIPPER(state, code) { // xóa shipper ra khỏi danh sách
      var shippers = state.shippers;
      for (var i = shippers.length - 1; i >= 0; i--) {
        if (shippers[i].code == code) {
          shippers.splice(i, 1)
          break;
        }
      }
      state.shippers = shippers
    },

    /////////// DÀNH CHO ĐƠN HÀNG
  	VUEX_SOCKET_V2_CLIENT_ORDERS(state, orders){ // chỉ lấy cái của mình emit đi thôi. // Tại vì có thể khác date_pickup
  		state.orders = orders
  	},
    VUEX_SOCKET_V2_CLIENT_NEW_ORDER(state, order){ // nhận từ department_code
      if (state.date_pickup == order.date_pickup)
        state.orders.push(order);
    },
    VUEX_SOCKET_V2_CLIENT_UPDATE_ORDER(state, order) { // cập nhật thì so về mã đơn nên ko sợ nhầm date_pickup // department_code
      if (state.date_pickup != order.date_pickup) return;
      
      var orders = state.orders;
      var index = -1;
  		for (var i = orders.length - 1; i >= 0; i--) {
        if (orders[i].code == order.code) {
          Vue.set(orders, i, order)
          index = i;
          break;
        }
      }

      if(index == -1){
        orders.push(order);
      }
      state.orders = orders
  	},
    saveDate_pickup(state, date_pickup){
      state.date_pickup = date_pickup;
    },
    setTDV(state, tdv){
      state.tdv = tdv;
    }
  },
  actions: {
  	setTDV (context, tdv) {
      context.commit('setTDV', tdv);
    },
    
    saveDatePickup (context, date_pickup) {
      context.commit('saveDate_pickup', date_pickup);
    }
  },
  getters: {
    getDatePickup(state) {
      return state.date_pickup
    },
    getTdv(state) {
      return state.tdv
    },
    getOrders(state) {
      return state.orders;
    },
    getShippers(state) {
      return state.shippers;
    },
    getServices(state) {
      return state.services;
    }
  },
  modules: {
  }
})
