import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { VueContext } from 'vue-context';
import VueSocketIO from 'vue-socket.io'
import axios from 'axios';
Vue.prototype.$axios = axios
Vue.config.productionTip = false

///// BOOTSTRAP /////
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
///// BOOTSTRAP /////

/////
Vue.component('vue-context', VueContext)
import 'vue-context/dist/css/vue-context.css';
/////

import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

Vue.use(require('vue-shortkey'))

import './assets/main.scss'

import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster' // replace src with dist if you have Babel issues
Vue.component('GmapCluster', GmapCluster)
Vue.use(VueGoogleMaps, {
  load: {
    libraries: ['places', 'geometry'], // This is required if you use the Autocomplete plugin
    key: 'AIzaSyAicwHT8xpVaQC2Ouak2A62wSoGL7kOV8Q',
    region: 'VI',
    language: 'vi',
  },
})

//////////////// SOCKET IO
import ENV from '../env.js';
Vue.use(new VueSocketIO({
    debug: false,
    connection: ENV.SOCKET,
    vuex: {
      store,
      actionPrefix: 'VUEX_',
      mutationPrefix: 'VUEX_'
    },
    options: { path: "/socket.io/" } //Optional options
}))
////////////////

import VeeValidate, { Validator } from 'vee-validate';
import vi from 'vee-validate/dist/locale/vi.js';
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: "veeFields",
  errorBagName: "veeErrors"
});
Validator.localize('vi', vi);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
