import router from '@/router'
import Tdv from '../_models/Tdv'
import store from '../store'

async function auth (to, from, next ) {
	let tdv = Tdv.get();
	if (tdv == null) {
		router.push({name:"Login"});
	}
  store.dispatch('setTDV', tdv)

  return next();
}

export default {
	auth
}