import localStore from '@/_modules/LocalStore';

class TDV {
  get() {
    let data = localStore.get('tdv');
    return data
  }

  set(tdv) {
    localStore.set('tdv', tdv);
    return true;
  }

  delete() {
    this.set(null);
    return true;
  }
}

export default new TDV();
