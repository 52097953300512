//////////////////// PRODUCTION
let URL_RESOURCE = 'https://resource.onggianoelcantho.com'
let SOCKET = 'https://socket.onggianoelcantho.com'
let URL_MAP = 'https://map-map.citiship.net/tile/{z}/{x}/{y}.png'
let URL_ROUTER = 'https://router-map.citiship.net'
let URL_APIADMIN = 'https://apiadmin-local.onggianoelcantho.com'


//////////////////// DEVELOPMENT
const debug = process.env.NODE_ENV !== 'production'
if (debug) {
	SOCKET = 'http://localhost:8083';
	URL_RESOURCE = 'http://resource.citiship.io/';
}

export default {
	SOCKET,
	URL_RESOURCE,
	URL_MAP,
	URL_ROUTER,
	URL_APIADMIN
}
